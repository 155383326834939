<template>
  <page :styleWrap='{background: "#f6f6f6"}' ref='wrap'>
    <div>
      <div class="searchPanel f_flex f_a_c f_j_sb">
        <search class="f_g1" placeholder="搜索" v-model='word' shape='round' ></search>
        <div class="cancle act_link" @click="$router.back()">取消</div>
      </div>
      <div class="resContent" ref='content' v-show='result.length'>
        <div class="labelArchor">常用</div>
        <div
          v-for='(item, index) in recentStyleNoList'
          @click='pickItem(item)'
          :key='index'>
          <div class="f_flex f_a_c resItem underline" v-if='item.styleNo'>
            <div>{{item.styleNo}}</div>
            <div v-if='item.isSym && word' class="newLabel theme_c">新建</div>
          </div>
        </div>
      </div>
<div class="resContent" ref='content' v-show='result.length'>
    <div class="labelArchor">全部</div>
        <div
          v-for='(item, index) in result'
          @click='pickItem(item)'
          :key='index'>
          <div class="f_flex f_a_c resItem underline" v-if='item.styleNo'>
            <div>{{item.styleNo}}</div>
            <div v-if='item.isSym && word' class="newLabel theme_c">新建</div>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import { Search } from 'vant';
import styleNoApi from '@/api/styleNo'
import {mapActions} from 'vuex'
import scrollMixins from '@/common/mixins/scroll'

export default {
  components: {
    Search,
  },
  mixins: [scrollMixins],
  data: ()=> ({
    result: [],
    recentStyleNoList: [],
    word: '',
    pageNo: 1,
    pageSize: 20,
    isEnd: false
  }),
  mounted() {
    const wrap = this.$refs.wrap.$refs.pageContent_contain
    const content = this.$refs.content
    this.getList()
    this.onContentScroll(wrap, content, this.isEnd, ()=> {
      this.getList()
    })
  },
  watch: {
    word(n) {
      this.$global.debounce(()=> {
        this.pageNo = 1
        this.result = []
        this.isEnd = false
        this.getList(n)
      })
    }
  },
  methods: {
    ...mapActions([
      'set_styleNum'
    ]),
    getList(n) {
      const {companyId, postsSource} = this.$route.query
      const companyInfo = this.$global.getItem('companyInfo', true)
      if (!this.isEnd) {
        styleNoApi.styleNoList({
          styleNo: n || this.word,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
          styleNoSource: postsSource,
          bizId: companyId || companyInfo.companyId,
        }).then(res => {
          if (res.data && res.data.styleNoList) {
            const list = res.data.styleNoList
            const hasThisStyleNo = list.find(item => item.styleNo === n)
            if (!hasThisStyleNo && this.pageNo == 1) {
              list.unshift({
                id: 0,
                styleNo: n,
                isSym: true
              })
            }
            this.result.push(...list)
            if (this.recentStyleNoList.length == 0) {
              this.recentStyleNoList.push(...res.data.recentStyleNoList)
            }
            
          }
          if (!res.data.endFlag) {
            this.pageNo++
          } else {
            this.isEnd = true
          }
        })
      }
    },
    pickItem(item) {
      if (item.isSym) {
        this.createStyleNo(item)
      } else {
        this.set_styleNum(item)
        setTimeout(() => {
          this.$router.back()
        })
      }
    },
    createStyleNo(item) {
      const {companyId, postsSource} = this.$route.query
      const companyInfo = this.$global.getItem('companyInfo', true)
      const obj = {
        styleNoSource: postsSource,
        bizId: companyId || companyInfo.companyId,
        styleNo: item.styleNo
      }
      styleNoApi.styleNoCreate(obj).then(res => {
        if (res.data) {
          this.$toast(res.data.msg)
          // {id, styleNo} 修改格式保持和分页查询统一
          this.set_styleNum({
            styleId: res.data.bizId,
            styleNo: item.styleNo
          })
          setTimeout(() => {
            this.$router.back()
          }, 20)
        }
      })
    }
  },
}
</script>

<style lang="less" scoped>
.searchPanel {
  padding-left: 10px;
  position: sticky;
  top: 0;
  z-index: 9;
  background: #fff;
}
.cancle {
  font-size: 15px;
  color: #4A4A4A;
  margin: 0 15px;
}

.resContent {
  padding: 0px;
}
.resItem {
  font-size: 16px;
  color: #2D2D2D;
  padding: 12px 20px;
  background: #fff;
}
.newLabel {
  font-size: 12px;
  border: 1px solid #4F92E6;
  padding: 1px 2px;
  line-height: 12px;
  border-radius: 2px;
  margin-left: 7px;
}


.labelArchor {
    z-index: 1;
    box-sizing: border-box;
    padding: 0 16px;
    color: #323233;
    font-weight: 500;
    font-size: 14px;
    line-height: 32px;
    background-color: transparent;

}
</style>